import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import RubriqueCard from "layouts/gestion_sante_animale/component/RubriqueCard";
import globalStyles from "style";
import { Grid } from "@mui/material";
import SearchBar from "layouts/gestion_sante_animale/component/SearchBar";

import MDTypography from "components/MDTypography";
import ServicesAPI from "./../../services/servicesAPI";
import { type RubriqueSanteAnimalType } from "constant/Types";
import RubriqueDialogAction from "layouts/gestion_sante_animale/component/RubriqueDialogAction";
import PDFViewerDialog from "layouts/gestion_sante_animale/component/PDFViewerDialog";
import LoadComponent from "components/LoadComponent/index";
// import COLORS from "../../../assets/theme/base/colors.js"
import colors from "assets/theme/base/colors";
import { LAST_PATH } from "constant";
import {
  PATH_MEDICAMENT,
  ENDPOINT_GET_MEDICAMENT,
  MODULE_MEDICAMENT,
  TITLE_MEDICAMENT,
  // PATH_TRAITEMENT,
  // MODULE_TRAITEMENT,
  // TITLE_TRAITEMENT,
  MODULE_MALADIE,
  PATH_MALADIE_ZOONOTIQUE,
  ENDPOINT_GET_MALADIE_ZOONOTIQUE,
  MODULE_MALADIE_ZOONOTIQUE,
  TITLE_MALADIE_ZOONOTIQUE,
  PATH_MALADIE_NON_ZOONOTIQUE,
  ENDPOINT_GET_MALADIE_NON_ZOONOTIQUE,
  MODULE_MALADIE_NON_ZOONOTIQUE,
  TITLE_MALADIE_NON_ZOONOTIQUE,
  PATH_VACCIN,
  MODULE_VACCIN,
  TITLE_VACCIN,
  ENDPOINT_GET_VACCIN,
  PATH_DIAGNOSTIC,
  MODULE_DIAGNOSTIC,
  ENDPOINT_GET_DIAGNOSTIC,
  ENDPOINT_GET_DIAGNOSTIC_MALADIE,
  TITLE_DIAGNOSTIC,
  PATH_ANNEXE,
  TITLE_ANNEXE,
  ENDPOINT_GET_ANNEXE,
  MODULE_ANNEXE,
  PATH_TECHNIQUE,
  TITLE_TECHNIQUE,
  ENDPOINT_GET_TECHNIQUE,
  MODULE_TECHNIQUE,
} from "./../../../constant/moduleInfo";

export default function IndexRubrique(): any {
  const location = useLocation();
  const servicesAPI = ServicesAPI();
  // const navigate = useNavigate();
  const { isAlert, isLoading, message, alertSeverity, handleLoad } = servicesAPI;

  const [listData, setListData] = useState<RubriqueSanteAnimalType[]>([]);
  const [listFilterData, setlistFilterData] = useState<RubriqueSanteAnimalType[]>([]);
  const [listMaladieDiagnostic, setListMaladieDiagnostic] = useState([]);
  const [selectedItem, setSelectedItem] = useState<RubriqueSanteAnimalType>({
    id: 0,
    title: "",
    description: "",
    nom: "",
  });

  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [showName, setShowName] = useState("");
  const [openDialogAction, setOpenDialogAction] = useState(false);
  const [openPDFDialog, setOpenPDFDialog] = useState(false);
  const [pdfURL, setPdfURL] = useState("");
  const [pdfDiagnosticMaladieURL, setPdfDiagnosticMaladieURL] = useState("");
  // Récupère la liste des données et les range dans un tableau
  const getAllData = (endpoint: string): void => {
    try {
      servicesAPI.getAll(endpoint).then((res: any) => {
        if (Array.isArray(res)) {
          // Ordonne le table par ordre alphabétique
          const result: RubriqueSanteAnimalType[] = res
            .map((i) => {
              let description: string = "";
              let title: string = "";
              let nom: string = "";
              /* if (endpoint.includes("Medicament")) {
                description = i.type;
                title = `${i.nom} / ${i.famille}`;
                nom = `${i.nom} / ${i.famille}`;
              } /*  else if (endpoint.includes("Maladie")) {
                description = i.description;
                title = i.nom;
                nom = i.nom;
              } */ if (endpoint.includes("Vaccin")) {
                description = i.typeVaccin;
                title = `${i.nomCommerce}`;
                // title = `${i.nomCommerce} / ${i.typeVaccin}`;
                nom = `${i.nomCommerce}`;
              } /* else if (endpoint.includes("diagnostic")) {
                // description = i.typeVaccin;
                title = i.nom;
                nom = i.nom;
              } /* else if (location.pathname === PATH_ANNEXE) {
                // description = i.typeVaccin;
                title = i.nom;
                nom = i.nom;
              } */ else {
                title = i.nom;
                nom = i.nom;
              }
              // console.log("ddd", i);
              const item = {
                ...i,
                ...{ title, description, nom },
              };
              return item;
            })
            .sort((a, b) => {
              const nameA = a.title.toUpperCase(); // ignore upper and lowercase
              const nameB = b.title.toUpperCase(); // ignore upper and lowercase

              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }

              // names must be equal
              return 0;
            });

          setListData(result);
          setlistFilterData(result);
          // console.log("ddd", selectedItem);
        }
      });
    } catch (error) {
      console.log("get_all_data error", error, listData.length);
    }
  };
  const getAllMaladieByDiagnostic = (diagnostic: string): void => {
    try {
      // console.log("dddd", `${ENDPOINT_GET_DIAGNOSTIC_MALADIE}${diagnostic}`);
      servicesAPI.getAll(`${ENDPOINT_GET_DIAGNOSTIC_MALADIE}${diagnostic}`).then((res: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        if (Object.keys(res).length > 0 && Array.isArray(res.maladies)) {
          // console.log("dddd", res);
          const result = res.maladies
            .map((i) => {
              const item = {
                ...i,
                ...{ label: i.nom },
              };
              return item;
            })
            .sort((a, b) => {
              const nameA = a.label.toUpperCase(); // ignore upper and lowercase
              const nameB = b.label.toUpperCase(); // ignore upper and lowercase

              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }

              // names must be equal
              return 0;
            });

          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setListMaladieDiagnostic(result);
        }
      });
    } catch (error) {
      console.log("get_all_data error", error, listData.length);
    }
  };
  const getRubriqueInfos = (): void => {
    /* if (location.pathname === PATH_TRAITEMENT) {
      const traitementData = [
        {
          id: 1,
          title: "Médicaments",
          description: "",
          nom: "",
        },
        {
          id: 2,
          title: "Vaccins",
          description: "",
          nom: "",
        },
        {
          id: 3,
          title: "Traitement épicutané du bétail",
          description: "Annexe 1",
          nom: "",
        },
        {
          id: 4,
          title: "Pédiluve acaricide",
          description: "Annexe 2",
          nom: "",
        },
      ];
      setTitle(TITLE_TRAITEMENT);
      setShowName(MODULE_TRAITEMENT);
      setType(MODULE_TRAITEMENT);
      setListData(traitementData);
      setlistFilterData(traitementData);
    } else { */
    let titleRubrique: string = "";
    let endPointRubrique: string = "";
    let module: string = "";
    let typeRubrique: string = "";
    if (location.pathname === PATH_MEDICAMENT) {
      titleRubrique = TITLE_MEDICAMENT;
      endPointRubrique = ENDPOINT_GET_MEDICAMENT;
      module = MODULE_MEDICAMENT;
      typeRubrique = MODULE_MEDICAMENT;
    } else if (location.pathname === PATH_VACCIN) {
      titleRubrique = TITLE_VACCIN;
      endPointRubrique = ENDPOINT_GET_VACCIN;
      module = MODULE_VACCIN;
      typeRubrique = MODULE_VACCIN;
    } else if (location.pathname === PATH_ANNEXE) {
      titleRubrique = TITLE_ANNEXE;
      endPointRubrique = ENDPOINT_GET_ANNEXE;
      module = MODULE_ANNEXE;
      typeRubrique = MODULE_ANNEXE;
    } else if (location.pathname === PATH_MALADIE_ZOONOTIQUE) {
      titleRubrique = TITLE_MALADIE_ZOONOTIQUE;
      endPointRubrique = ENDPOINT_GET_MALADIE_ZOONOTIQUE;
      module = MODULE_MALADIE_ZOONOTIQUE;
      typeRubrique = MODULE_MALADIE;
    } else if (location.pathname === PATH_MALADIE_NON_ZOONOTIQUE) {
      titleRubrique = TITLE_MALADIE_NON_ZOONOTIQUE;
      endPointRubrique = ENDPOINT_GET_MALADIE_NON_ZOONOTIQUE;
      module = MODULE_MALADIE_NON_ZOONOTIQUE;
      typeRubrique = MODULE_MALADIE;
    } else if (location.pathname === PATH_DIAGNOSTIC) {
      titleRubrique = TITLE_DIAGNOSTIC;
      endPointRubrique = ENDPOINT_GET_DIAGNOSTIC;
      module = MODULE_DIAGNOSTIC;
      typeRubrique = MODULE_DIAGNOSTIC;
    } else if (location.pathname === PATH_TECHNIQUE) {
      titleRubrique = TITLE_TECHNIQUE;
      endPointRubrique = ENDPOINT_GET_TECHNIQUE;
      module = MODULE_TECHNIQUE;
      typeRubrique = MODULE_TECHNIQUE;
    }
    setTitle(titleRubrique);
    getAllData(endPointRubrique);
    setShowName(module);
    setType(typeRubrique);
    // }
  };
  useEffect(() => {
    localStorage.setItem(String(LAST_PATH), location.pathname);
    setListData([]);
    setlistFilterData([]);
    setListMaladieDiagnostic([]);
    setSelectedItem({
      id: 0,
      title: "",
      description: "",
      nom: "",
    });
    getRubriqueInfos();
  }, [location.pathname]);
  // ouvre un modal (boite de dialogue) contenant les boutons upload et download
  const handleOpenDialogAction = (value: RubriqueSanteAnimalType): void => {
    setSelectedItem(value);
    setOpenDialogAction(true);
    if (location.pathname === PATH_DIAGNOSTIC) {
      getAllMaladieByDiagnostic(value.title);
    }
  };
  const handleCloseDialogAction = (): void => {
    setSelectedItem({
      id: 0,
      title: "",
      description: "",
      nom: "",
    });
    setOpenDialogAction(false);
    setListMaladieDiagnostic([]);
    setPdfDiagnosticMaladieURL("");
  };
  // Récupère le fichier associer à l'élément sélectionné
  const handleOpenFile = (fileName: string, isDiagnosticbyMaladie: boolean): void => {
    try {
      // setPdfURL("");
      servicesAPI.downloadFile(fileName, type).then(async (res: any) => {
        if (res !== "error") {
          // Convert the PDF string to a Blob object
          const pdfBlob = new Blob([res], { type: "application/pdf" });
          // Create a URL for the Blob object
          const pdfBlobURL = URL.createObjectURL(pdfBlob);
          // open url in new tab
          // setIsPdfViewer(true);
          // setPdfUrl(pdfBlobURL);
          if (isDiagnosticbyMaladie) {
            setPdfDiagnosticMaladieURL(pdfBlobURL);
          } else {
            setOpenDialogAction(false);
            setPdfURL(pdfBlobURL);
            setOpenPDFDialog(true);
          }
        }
      });
    } catch (error) {
      console.log("handleGetFile error", error);
    }
  };
  // Upload un fichier pour l'associer à l'élément sélectionné
  const handleUplaodFile = (file: Blob, fileName: string): void => {
    try {
      if (file !== null) {
        /*  let endpoint= ""
        if(location.pathname=== PATH_ANNEXE) {
          endpoint=ENDPOINT_UPLOAD_ANNEXE
        } else {
          endpoint=
        } */
        servicesAPI.uploadFile(file, fileName).then(async (res: any) => {
          // uploadFile(file, name).then(async (res) => {
          if (res !== "axios_error") {
            // refresh();
            setTimeout(() => {
              console.log("upload over");
              // handleCloseDialogAction();
              handleOpenFile(fileName, false);
            }, 200);
          }

          // console.log("here i am////", res, file.name, selectedItem.nom);
        });
      } else {
        alert("Veuillez choisir un fichier");
      }
    } catch (error) {
      console.log("handlePostFile error", error);
    }
  };
  const handleClosePDFDialog = (): void => {
    setOpenPDFDialog(false);
    setPdfURL("");
    setSelectedItem({
      id: 0,
      title: "",
      description: "",
      nom: "",
    });
    setListMaladieDiagnostic([]);
    setPdfDiagnosticMaladieURL("");
  };
  const gobalClasses = globalStyles();
  // console.log("rrr", listFilterData);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <MDTypography
          display="block"
          variant="H1"
          fontWeight="medium"
          align="center"
          style={{ color: colors.success.main, marginBottom: 10 }}
        >
          {title}
        </MDTypography>
        <SearchBar dataArray={listData} setFilterArray={setlistFilterData} />
        <div className={gobalClasses.centerContent} style={{ margin: 15, width: "100%" }}>
          <Grid container spacing={3} sx={{}}>
            {listFilterData.map((i) => (
              <Grid item xs={12} lg={4} key={i.id}>
                <RubriqueCard cardData={i} handleOpenDialogAction={handleOpenDialogAction} />
              </Grid>
            ))}
          </Grid>
        </div>
        {openDialogAction && (
          <RubriqueDialogAction
            selectedItem={selectedItem}
            handleOpenFile={handleOpenFile}
            handleUploadFile={handleUplaodFile}
            handleClose={handleCloseDialogAction}
            open={openDialogAction}
            showName={showName}
          />
        )}
        {openPDFDialog && (
          <PDFViewerDialog
            selectedItem={selectedItem}
            pdfURL={pdfURL}
            handleClose={handleClosePDFDialog}
            open={openPDFDialog}
            listMaladieDiagnostic={listMaladieDiagnostic}
            handleOpenMaladieFile={handleOpenFile}
            pdfDiagnosticMaladieURL={pdfDiagnosticMaladieURL}
            setPdfDiagnosticMaladieURL={setPdfDiagnosticMaladieURL}
          />
        )}
        <LoadComponent
          isAlert={isAlert}
          isLoading={isLoading}
          message={message}
          alertSeverity={alertSeverity}
          handleLoad={handleLoad}
        />
      </div>
    </DashboardLayout>
  );
}
