/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useContext } from "react";
// import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import Form from "components/Form";
import LayoutContext from "context/LayoutContext";
import { ACTION_ADD } from "constant/dataTableActionConstant";

function indexTable() {
  const layoutContext = useContext(LayoutContext);
  const { title, columns, rows, handleDialogForm, onSelectedRow, handleSearch } = layoutContext;
  // console.log("componentModule", formConfig, isOpenForm);
  const openForm = () => {
    onSelectedRow({}, ACTION_ADD);
    handleDialogForm(true, ACTION_ADD);
  };
  return (
    <div>
      <Typography variant="h2">{title}</Typography>
      <MDButton
        type="submit"
        variant="gradient"
        color="info"
        sx={{ marginLeft: "auto", marginRight: 10 }}
        // style={{}}
        onClick={openForm}
      >
        Ajouter
      </MDButton>
      {rows.length > 0 && (
        <Box pt={3}>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage
            showTotalEntries
            noEndBorder
            canSearch
            handleSearch={handleSearch}
          />
        </Box>
      )}
      <Form />
    </div>
  );
}

const indexTableComponent = React.memo(indexTable);
export default indexTableComponent;
