import React, { useEffect, useState } from "react";
// import { Document, Page } from "react-pdf";
import PropTypes from "prop-types";
import { Dialog, DialogContent, Button, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
// import globalStyles from "style";
import "./iframeStyle.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const PDFViewerDialog = ({
  pdfURL,
  open,
  handleClose,
  selectedItem,
  listMaladieDiagnostic,
  handleOpenMaladieFile,
  pdfDiagnosticMaladieURL,
  setPdfDiagnosticMaladieURL,
}): JSX.Element => {
  // const { url } = useParams();
  // const location = useLocation();
  // const [selectedMaladie, setSelectedMaladie] = useState({});
  const [file, setfile] = useState("");
  const [marginTop, setMarginTop] = useState("");
  useEffect(() => {
    if (listMaladieDiagnostic.length > 0 && pdfDiagnosticMaladieURL !== "") {
      setfile(pdfDiagnosticMaladieURL as string);
      setMarginTop("250px");
    } else {
      setfile(pdfURL as string);
      setMarginTop("50px");
    }
  }, [pdfURL, pdfDiagnosticMaladieURL]);
  // const gobalClasses = globalStyles();
  console.log("dddd", marginTop);
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullScreen
    >
      {/* <DialogTitle id="customized-dialog-title">{selectedItem.title}</DialogTitle> */}
      <Button
        key={"close"}
        variant="contained"
        sx={{ color: "#000" }}
        onClick={() => handleClose()}
      >
        Retour
      </Button>
      {/* <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          left: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton> */}
      {listMaladieDiagnostic.length > 0 ? (
        <div style={{ marginTop: 10 }}>
          <Button key={"Diagnostic"} variant="text" onClick={() => setPdfDiagnosticMaladieURL("")}>
            {selectedItem.title}
          </Button>
          <Stack spacing={2} direction="row" sx={{ backgroundColor: "#ffe1c3", padding: "20px" }}>
            {listMaladieDiagnostic.map((i) => (
              <>
                <Button
                  fullWidth
                  key={i.id}
                  variant="outlined"
                  sx={{ color: "#000" }}
                  onClick={() => handleOpenMaladieFile(i.label, true)}
                >
                  {i.label}
                </Button>
              </>
            ))}
          </Stack>
          <div
            style={{
              position: "absolute",
              top: "180px",
              left: 0,
              right: 0,
              bottom: 0,
              overflow: "hidden",
            }}
          >
            <iframe
              src={file}
              title="Maladie "
              // width="100%"
              // height="2000px"
              // className="iframe-container"
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                overflowY: "scroll",
              }}
            >
              <p>
                Votre navigateur ne supporte pas les pdf{" "}
                <a href={file} download>
                  Télécharger le PDF
                </a>
                .
              </p>
            </iframe>
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50px",
            left: 0,
            right: 0,
            bottom: 0,
            overflow: "hidden",
          }}
        >
          <iframe
            src={file}
            title="Maladie "
            // width="100%"
            // height="2000px"
            // className="iframe-container"
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              overflowY: "scroll",
            }}
          >
            <p>
              Votre navigateur ne supporte pas les pdf{" "}
              <a href={file} download>
                Télécharger le PDF
              </a>
              .
            </p>
          </iframe>
        </div>
      )}

      <DialogContent dividers>
        {/* <div
          style={{
            position: "absolute",
            top: "150px",
            left: 0,
            right: 0,
            bottom: 0,
            overflow: "hidden",
          }}
        >
          <iframe
            src={file}
            title="Maladie "
            // width="100%"
            // height="2000px"
            // className="iframe-container"
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              overflowY: "scroll",
            }}
          >
            <p>
              Votre navigateur ne supporte pas les pdf{" "}
              <a href={file} download>
                Télécharger le PDF
              </a>
              .
            </p>
          </iframe>
          <p>
            Votre navigateur ne supporte pas les pdf{" "}
            <a href={file} download>
              Télécharger le PDF
            </a>
            .
          </p>
        </div> */}
        <p>
          <a href={file} download>
            Télécharger le PDF
          </a>
          .
        </p>
      </DialogContent>

      {/* </Dialog> */}
    </BootstrapDialog>
  );
};
/* const styles = {
  iframeContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
    overflowY: "scroll",
  },
}; */

export default PDFViewerDialog;
PDFViewerDialog.defaultProps = {
  pdfURL: "",
  open: false,
  handleClose: () => {},
  selectedItem: {
    id: 0,
    title: "",
    description: "",
  },
  listMaladieDiagnostic: [],
  handleOpenMaladieFile: () => {},
  setPdfDiagnosticMaladieURL: () => {},
  pdfDiagnosticMaladieURL: "",
};
PDFViewerDialog.propTypes = {
  pdfURL: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedItem: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  listMaladieDiagnostic: PropTypes.object,
  handleOpenMaladieFile: PropTypes.func,
  setPdfDiagnosticMaladieURL: PropTypes.func,
  pdfDiagnosticMaladieURL: PropTypes.string,
};
