/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MDInput from "components/MDInput";
import servicesAPI from "layouts/services/servicesAPI";
import LoadComponent from "components/LoadComponent";
// import { SUPER_ADMIN_ROLE, ADMIN_ROLE } from "constant/role";
import { LAST_PATH, USER_DATA_STORAGE } from "./../../../../constant/index";
import { PATH_MPSA } from "../../../../constant/moduleInfo";

function PswUpdateComponent({ open, handleClose, userToken }) {
  const navigate = useNavigate();
  const { isAlert, isLoading, message, alertSeverity, handleLoad, postData } = servicesAPI();
  const [userInfo, setUserInfo] = useState({});
  // const [userLieu, setUserLieu] = useState("");
  // const [pswCurrent, setPswCurrent] = useState("");
  // const [errorPswCurrent, setErrorPswCurrent] = useState("");
  const [pswNew, setPswNew] = useState("");
  const [errorPswNew, setErrorPswNew] = useState("");
  const setData = () => {
    try {
      let data = {};
      if (localStorage.getItem(String(LAST_PATH))) {
        const userToken = localStorage.getItem(USER_DATA_STORAGE);
        if (userToken !== null) {
          data = JSON.parse(userToken);
          console.log("data", data);
        }
      } else {
        data = userToken;
      }
      setUserInfo(data);
      // setUserLieu(lieu);
    } catch (error) {
      console.log("setData error", error);
    }
  };
  // console.log("ddddd", localStorage.getItem(String(LAST_PATH)));
  const handleChangePsw = async () => {
    // console.log(userInfo.email);
    try {
      const data = { email: userInfo.email, passWord: pswNew };
      // postData("Users/reset-password", data)
      const endpoint = "Users/reset-password";
      // const data = { email: selectedItem.email, passWord: pswNew};
      postData(endpoint, data)
        // const response = await axios.post(endpoint, data);
        // console.log("Response:", response.data);
        .then((res) => {
          if (res !== "error") {
            console.log("over ", res);
            handleClose();
            const loadData = {
              isAlert: true,
              message: "Mot de passe modifié avec succès. Veuillez vous reconnecter",
              isLoading: false,
              alertSeverity: "success",
            };
            handleLoad(loadData);
            if (localStorage.getItem(String(LAST_PATH))) {
              localStorage.clear(); // Effacement des données de l'utilisateur,
            }
            setTimeout(() => {
              // localStorage.setItem("user-tokenBCMPSA", JSON.stringify(userToken));
              navigate(PATH_MPSA); // Redirection vers la SIGN UP
            }, 200);
          }
        })
        .catch((error) => {
          console.log("ddddddd, error", error);
        });
    } catch (error) {
      console.log("handleChangePsw error", error);
    }
  };
  const validateForm = () => {
    // setErrorPswCurrent("");
    setErrorPswNew("");
    /* if (pswCurrent === "") {
      setErrorPswCurrent("Saisir une valeur");
    } else */ if (pswNew === "") {
      setErrorPswNew("Saisir une valeur");
    } else if (pswNew === "123456" || pswNew === "JD") {
      setErrorPswNew(`le mot de passe ${pswNew} n'est pas accepté`);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleChangePsw();
    }
  };
  useEffect(() => {
    setData();
  }, []);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Informations du Compte</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {/* <Typography gutterBottom>{userInfo.nom ?? "No data"}</Typography>
          <Typography gutterBottom>{}</Typography> */}
          <Box mb={2}>
            <MDInput
              type="text"
              label="Prenom & Nom"
              value={`${userInfo.preNom} ${userInfo.nom}`}
              fullWidth
              disabled
            />
          </Box>
          <Box mb={2}>
            <MDInput type="text" label="Email" value={userInfo.email} fullWidth disabled />
          </Box>
          {/* <Box mb={2}>
            <MDInput type="text" label="Lieu" value={userLieu} fullWidth disabled />
          </Box> */}
          <Box mb={2}>
            <MDInput
              type="text"
              label="Roles"
              value={
                userInfo.roles
                  ? userInfo.roles.map((i) => i.roleName).join(", ") ?? "Aucun Role"
                  : "Aucun Role"
              }
              fullWidth
              disabled
            />
          </Box>
          <Typography display="block" variant="button" fontWeight="medium">
            Modifier le mot de passe
          </Typography>
          {/* <Box mb={2} mt={2}>
            <MDInput
              type="password"
              label="Mot de passe Actuel"
              value={pswCurrent}
              fullWidth
              onChange={(event) => {
                setPswCurrent(event.target.value);
              }}
              error={!!errorPswCurrent}
              helperText={errorPswCurrent && errorPswCurrent}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  validateForm();
                }
              }}
            />
          </Box> */}
          <Box mb={2}>
            <MDInput
              type="password"
              label="Nouveau Mot de passe"
              value={pswNew}
              fullWidth
              onChange={(event) => {
                setPswNew(event.target.value);
              }}
              error={!!errorPswNew}
              helperText={errorPswNew && errorPswNew}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  validateForm();
                }
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={validateForm}>
            Modifier
          </Button>
        </DialogActions>
      </Dialog>
      {/* <LoadComponent loadData={{ isAlert, isLoading, message, alertSeverity, handleLoad }} /> */}
      <LoadComponent
        isAlert={isAlert}
        isLoading={isLoading}
        message={message}
        alertSeverity={alertSeverity}
        handleLoad={handleLoad}
      />
    </div>
  );
}

export default PswUpdateComponent;
PswUpdateComponent.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  userToken: PropTypes.object,
};
PswUpdateComponent.defaultProps = {
  open: false,
  handleClose: () => {},
  userToken: {},
};
