/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import ListIcon from "@mui/icons-material/List";

import { makeStyles } from "@mui/styles";
import colors from "assets/theme/base/colors";

const useStyles = makeStyles({
  cardContent: {
    display: "flex",
    flexDirection: "column",
    height: 120,
    backgroundColor: colors.success.main,
  },
  centerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconContainer: {
    marginTop: "auto",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});
/*  Component qui affiche les cardView */
function CustomCard({ cardData, handleShowDetails }) {
  const classes = useStyles();
  return (
    <Card onClick={() => handleShowDetails(cardData)} key={cardData.title}>
      <CardActionArea className={classes.centerContents}>
        <div className={classes.centerContent} style={{ margin: 15 }}>
          <CardMedia component="img" height="100" image={cardData.img} alt={cardData.title} />
        </div>
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h6" component="div" style={{ color: "white" }}>
            {/* cardData.title !== undefined && cardData.title.length > 25
              ? `${cardData.title.substring(0, 50)}...`
              : cardData.title */}
            {cardData.title}
          </Typography>
          <Typography variant="body2" color="white">
            {cardData.description !== undefined && cardData.description.length > 50
              ? `${cardData.description.substring(0, 50)}...`
              : cardData.description}
          </Typography>
          <Box className={classes.iconContainer}>
            <IconButton style={{ color: "white" }}>
              <VisibilityIcon fontSize="medium" sx={{ mt: -0.25, color: "disabled" }} />
            </IconButton>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

CustomCard.defaultProps = {
  cardData: {},
  handleShowDetails: () => {},
};
CustomCard.propTypes = {
  cardData: PropTypes.object,
  handleShowDetails: PropTypes.func,
};
export default CustomCard;
