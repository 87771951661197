/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import imgMedicament from "assets/images/medicament.png";
import imgMaladie from "assets/images/maladie.png";
import imgVaccin from "assets/images/vaccin.png";
import imgDiagnostic from "assets/images/veterinarian.png";
import imgTraitement from "assets/images/traitment.png";
// import imgAnnexe from "assets/images/backlog.png";
import { Grid } from "@mui/material";
import CustomCard from "layouts/gestion_sante_animale/component/CustomCard";
import globalStyles from "style";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PATH_DIAGNOSTIC,
  PATH_MALADIE,
  PATH_MALADIE_NON_ZOONOTIQUE,
  PATH_MALADIE_ZOONOTIQUE,
  PATH_MEDICAMENT,
  PATH_MPSA,
  PATH_TRAITEMENT,
  TITLE_DIAGNOSTIC,
  TITLE_MALADIE,
  TITLE_MALADIE_NON_ZOONOTIQUE,
  TITLE_MEDICAMENT,
  TITLE_TRAITEMENT,
  TITLE_VACCIN,
  PATH_VACCIN,
  TITLE_MALADIE_ZOONOTIQUE,
  TITLE_MPSA,
  TITLE_TECHNIQUE,
  PATH_TECHNIQUE,
  // PATH_ANNEXE,
  // TITLE_ANNEXE,
} from "../../constant/moduleInfo";
import { LAST_PATH } from "constant";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";

function Index_sante_animale() {
  const navigate = useNavigate();
  const location = useLocation();
  const [gridData, setGridData] = useState([]);
  const [title, setTitle] = useState("");
  /* Element à afficher sur le cardview */
  const mpsaCardData = [
    {
      id: 1,
      title: TITLE_MALADIE,
      img: imgMaladie,
      route: PATH_MALADIE,
      // endPointURL: "Maladie",
    },
    {
      id: 1,
      title: TITLE_TECHNIQUE,
      img: imgMaladie,
      route: PATH_TECHNIQUE,
      // endPointURL: "Maladie",
    },
    {
      id: 2,
      title: TITLE_DIAGNOSTIC,
      img: imgDiagnostic,
      route: PATH_DIAGNOSTIC,
      // endPointURL: "Maladie",
    },
    {
      id: 3,
      title: TITLE_TRAITEMENT,
      img: imgTraitement,
      route: PATH_TRAITEMENT,
    },
    /* {
      id: 3,
      title: "Medicaments",
      img: imgMedicament,
      route: "/sante_animal/medicaments",
    },
    { id: 4, title: "Vaccins", img: imgVaccin, route: "/sante_animal/vaccins" }, */
  ];
  const maladieCardData = [
    {
      id: 1,
      title: TITLE_MALADIE_NON_ZOONOTIQUE,
      img: imgMaladie,
      route: PATH_MALADIE_NON_ZOONOTIQUE,
    },
    {
      id: 2,
      title: TITLE_MALADIE_ZOONOTIQUE,
      img: imgMaladie,
      route: PATH_MALADIE_ZOONOTIQUE,
    },
  ];
  const traitementCardData = [
    {
      id: 1,
      title: TITLE_MEDICAMENT,
      img: imgMedicament,
      route: PATH_MEDICAMENT,
    },
    { id: 2, title: TITLE_VACCIN, img: imgVaccin, route: PATH_VACCIN },
    // { id: 3, title: TITLE_ANNEXE, img: imgAnnexe, route: PATH_ANNEXE },
  ];
  // Naviguer vers l'élément choisi
  const onChange_Component = (item) => {
    if (item.route !== undefined) {
      // navigate(item.route, { state: { title: item.title, endPointURL: item.endPointURL } });
      localStorage.setItem(String(LAST_PATH), item.route);
      navigate(item.route);
    } else {
      alert("Rubrique Indisponible");
    }
  };
  useEffect(() => {
    localStorage.setItem(String(LAST_PATH), location.pathname);
    if (location.pathname === PATH_MPSA) {
      setGridData(mpsaCardData);
      setTitle(TITLE_MPSA);
    } else if (location.pathname === PATH_TRAITEMENT) {
      setGridData(traitementCardData);
      setTitle(TITLE_TRAITEMENT);
    } else if (location.pathname === PATH_MALADIE) {
      setGridData(maladieCardData);
      setTitle(TITLE_MALADIE);
    }
  }, [location.pathname]);
  const gobalClasses = globalStyles();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDTypography
        display="block"
        variant="H1"
        fontWeight="medium"
        align="center"
        style={{ color: colors.success.main }}
      >
        {title}
      </MDTypography>
      <div className={gobalClasses.centerContent} style={{ margin: 15, with: "100%" }}>
        <Grid container spacing={3} sx={{}}>
          {gridData.map((i) => (
            <Grid item xs={12} lg={6} key={i.id}>
              <CustomCard cardData={i} handleShowDetails={onChange_Component} />
            </Grid>
          ))}
        </Grid>
      </div>
    </DashboardLayout>
  );
}

export default Index_sante_animale;
