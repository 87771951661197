/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";
// import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
// import CoverLayout from "layouts/authentication/components/CoverLayout";
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import bgImage from "assets/images/che.jpg";
import betaclic_logo from "assets/images/log1.png";

import AlertContent from "components/MDAlert/AlertContent";

import api_url from "api_url";
import "../Basic.css";

function Cover() {
  const navigate = useNavigate();
  const [email, set_email] = useState("");
  const [isError_email, set_isError_email] = useState(false);
  const [errorMessage_email, set_errorMessage_email] = useState("");

  const [psw1, set_psw1] = useState("");
  const [isError_psw1, set_isError_psw1] = useState(false);
  const [errorMessage_psw1, set_errorMessage_psw1] = useState("");

  const [psw2, set_psw2] = useState("");
  const [isError_psw2, set_isError_psw2] = useState(false);
  const [errorMessage_psw2, set_errorMessage_psw2] = useState("");

  const [isLoading, set_isLoading] = useState(false);

  const [isError, set_isAlert] = useState(false);
  const [error_msg, set_alert_msg] = useState("");

  // const URL = `${api_url}/users`;
  const URL = `${api_url}/auth/registrer`;

  const initialize_data = () => {
    set_isAlert(false);
    set_alert_msg("");

    set_isError_email(false);
    set_errorMessage_email("");

    set_isError_psw1(false);
    set_errorMessage_psw1("");

    set_isError_psw2(false);
    set_errorMessage_psw2("");
    // set_alert_color("");
  };
  const handle_loading = (value) => {
    set_isLoading(value);
  };
  const handle_alert = (value) => {
    set_isAlert(value.isError);
    set_alert_msg(value.error_msg);
  };
  const go_to_main = (user_token) => {
    localStorage.clear();
    localStorage.setItem("user-token", user_token);
    setTimeout(() => {
      navigate("/");
    }, 200);
  };
  /* const update_etat = (user_data) => {
    let alert_data = {};
    try {
      handle_loading(true);
      alert_data = { isAlert: false, alert_msg: "", alert_color: "" };
      handle_alert(alert_data);
      const put_etat_data = {
        id_user: user_data.id_user,
        etat_user: 1,
      };
      axios
        .put(`${URL}/update_etat`, put_etat_data)
        .then((response) => {
          const result = response.data;
          console.log("signup update_etat", result);
          handle_loading(false);
          console.log("update user", result);
          alert_data = {
            isAlert: true,
            alert_msg: "Opération effectuée avec succès",
            alert_color: "success",
          };
          handle_alert(alert_data);
          // refresh();
          go_to_main(JSON.stringify(user_data));
        })
        .catch((error) => {
          handle_loading(false);
          alert_data = { isAlert: true, alert_msg: `${error}`, alert_color: "error" };
          handle_alert(alert_data);
        });
    } catch (error) {
      alert_data = { isAlert: true, alert_msg: `${error}`, alert_color: "error" };
      handle_alert(alert_data);
    }
  }; */
  /* const handle_signup = (user_data) => {
    // console.log("handle_signup start");
    let error_data = {};
    try {
      handle_loading(true);
      error_data = { isError: false, error_msg: "" };
      handle_alert(error_data);
      axios.post(`${URL}/sign_up`, user_data).then((response) => {
        const result = response.data;
        handle_loading(false);
        // console.log("axios sign up result", result);
        if (result.error) {
          // console.log("result error ", result.error);
          error_data = { isError: true, error_msg: result.error.message };
          handle_alert(error_data);
        } else {
          // go_to_main(JSON.stringify(user_data));
          update_etat(user_data);
        }
        // set_isLoading(false);
      }).catch = (error) => {
        console.log("signup error", error);
        error_data = { isError: true, error_msg: error };
        handle_alert(error_data);
      };
    } catch (error) {
      error_data = { isError: true, error_msg: `${error}` };
      handle_alert(error_data);
    }
  }; */
  const get_user_info = (data) => {
    // console.log("get_user_info start");
    let error_data = {};
    try {
      handle_loading(true);
      error_data = { isError: false, error_msg: "" };
      handle_alert(error_data);
      axios.post(`${URL}`, data).then((response) => {
        // axios.post(`${URL}/get_by_email`, data).then((response) => {
        handle_loading(false);
        const result = response.data;
        // console.log("post get_user_info result", result.token);
        go_to_main(result.token);
        /* if (result.length > 0) {
          // console.log("data", result[0].etat_user);
          // go_to_main(JSON.stringify(result));
          if (result[0].droit_user !== "user") {
            if (result[0].etat_user === 1) {
              error_data = {
                isError: true,
                error_msg: "Votre compte est déjà activé. Veuillez vous connecter",
              };
              handle_alert(error_data);
            } else if (result[0].etat_user === 2) {
              // go_to_main(JSON.stringify(result[0]));
              const psw_data = { password_: psw1 };
              handle_signup({ ...result[0], ...psw_data });
            } else {
              error_data = { isError: true, error_msg: "Votre Compte a été bloqué" };
              handle_alert(error_data);
            }
          } else {
            error_data = {
              isError: true,
              error_msg: "Vous ne disposer des droits pour accéder à cette interface",
            };
            handle_alert(error_data);
          }
        } else {
          error_data = {
            isError: true,
            error_msg: "Compte Inexistant. Vérifier votre adresse email",
          };
          handle_alert(error_data);
        } */
        // set_isLoading(false);
      }).catch = (error) => {
        console.log("get_user_info axios error", error);
        // set_isError(true);
        // set_error_msg("Error");
        error_data = { isError: true, error_msg: error };
        handle_alert(error_data);
      };
    } catch (error) {
      console.log("get_user_info error", error);
      error_data = { isError: true, error_msg: `${error}` };
      handle_alert(error_data);
    }
  };
  const check_form = (event) => {
    event.preventDefault();
    // console.log("check_form", new Date());
    // set_isError(false);
    // set_error_msg("");
    // handle_alert({ isError: false, error_msg: "" });
    initialize_data();
    if (email === "") {
      set_isError_email(true);
      set_errorMessage_email("Veuillez saisir votre adresse email");
    } else if (psw1 === "") {
      set_isError_psw1(true);
      set_errorMessage_psw1("Veuillez saisir votre mot de passe");
    } else if (psw1 !== psw2) {
      set_isError_psw2(true);
      set_errorMessage_psw2("Veuillez saisir le même mot de passe");
    } else {
      // const data = {
      //   email_user: email,
      //   password_: psw1,
      // };
      const data = {
        email,
        password: psw1,
        firstname: "",
        lastname: "",
      };
      set_isLoading(true);
      // handle_signin(data);
      get_user_info(data);
    }
  };
  console.log("d", isLoading);
  return (
    <BasicLayout img={bgImage}>
      <Card className="form-container">
        <MDBox>
          <img src={betaclic_logo} className="img-flui" alt="Beta Click" />
          <h2> Activez votre compte!</h2>
          {/* <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography> */}
          {/* <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography> */}
        </MDBox>
        <MDBox pt={12} pb={3} px={30}>
          <MDBox component="form" role="form" /* onSubmit={check_form} */>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                // onChange={handleChange_email}
                onChange={(event) => {
                  set_email(event.target.value);
                }}
                error={isError_email}
                helperText={errorMessage_email}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Mot de passe"
                fullWidth
                // onChange={handleChange_psw1}
                onChange={(event) => {
                  set_psw1(event.target.value);
                }}
                error={isError_psw1}
                helperText={errorMessage_psw1}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Confirmer le mot de passe"
                fullWidth
                onChange={(event) => {
                  set_psw2(event.target.value);
                }}
                error={isError_psw2}
                helperText={errorMessage_psw2}
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox> */}
            <MDBox mt={8} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={check_form}>
                Activer mon Compte
              </MDButton>
            </MDBox>
            <MDBox mt={30} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Compte déjà activé?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Se Connecter
                </MDTypography>
              </MDTypography>
            </MDBox>
            {isError && <AlertContent color="error" msg={error_msg} />}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
